import {useSelector} from 'react-redux';

const useTranslationCustom = () => {
    const {translations} = useSelector(state => state.appTranslations);

    const T = (key, templateValues = {}) => {
        const [parentKey, childKey] = key ? key.split(':') : [null, null];

        if (translations === null || translations?.length === 0 || !parentKey || !childKey) {
            return '...';
        }

        let translation = translations[parentKey]?.[childKey] || '';

        Object.keys(templateValues).forEach(templateKey => {
            const templateValue = templateValues[templateKey];
            const regex = new RegExp(`{{${templateKey}}}`, 'g');
            translation = translation.replace(regex, templateValue);
        });

        // Todo > Check if key is missing
        if (!translation) {
            console.log(parentKey + ':' + childKey);
        }

        return `${translation}`;
    };

    return {T};
};

export default useTranslationCustom;
