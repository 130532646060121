import React, {useContext} from "react";
import Image1 from "../../assets/images/hotal-1.png";
import BadgeIcon from "../../assets/images/icons/aroplane-bed.svg";
import BadgeIconDark from "../../assets/images/icons/aroplane-bed-dark.svg";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getSearchFilters} from "../../redux/slices/searchFilterSlice";
import {
    getCurrencyBadge,
    getListingItemFilters,
    navigateToDetailPage,
} from "../../helpers/common";
import {getLoginConfig} from "../../redux/slices/loginconfigSlice";
import AppConfig from "../../helpers/config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMapMarkerAlt,
    faStar,
    faChevronUp,
    faChevronDown,
} from "@fortawesome/pro-solid-svg-icons";
import {
    Accordion,
    AccordionContext,
    useAccordionButton,
} from "react-bootstrap";
import FavoriteAccordion from "../FavoriteAccordion";
import PropTypes from "prop-types";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const HotelItemBranchSmall = ({offer}) => {
    const {T} = useTranslationCustom();
    const loginconfig = useSelector(getLoginConfig);
    const searchfilters = useSelector(getSearchFilters);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let currency = "$";
    const filters = getListingItemFilters(offer, T, offer?.objHotel?.newPackagedOffer?.flightOffer || offer?.flightOffer);

    if (offer?.detailsLink) {
        const objUrl = new URL(offer?.detailsLink);
        const searchparams = new URLSearchParams(objUrl.search);
        currency = searchparams.get("currency");
    }

    function ContextAwareToggle({eventKey, callback}) {
        const {activeEventKey} = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey)
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <button type="button" className={"action-btn"} onClick={decoratedOnClick}>
                {isCurrentEventKey ? T("Buttons:View_Less") : T("Buttons:View_More")}{" "}
                <FontAwesomeIcon
                    icon={isCurrentEventKey ? faChevronUp : faChevronDown}
                />
            </button>
        );
    }

    ContextAwareToggle.propTypes = {
        eventKey: PropTypes.string.isRequired,
        callback: PropTypes.func,
    };

    const handleOnClick = () => {
        !offer?.detailsLink &&
        navigateToDetailPage(
            offer,
            dispatch,
            loginconfig,
            searchfilters,
            navigate,
            "hotel"
        )
    }

    return (
        <>
            <Accordion>
                <div className="packages-card-list" onClick={() => handleOnClick()}>
                    <div className="small-card-image-holder">
                        <img
                            src={offer?.thumbnailUrl || offer?.hotelImg || Image1}
                            className="covered-image"
                            alt={"hotel-image"}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleOnClick();
                            }}
                        />
                        <div className="card-badge">
                            <img src={BadgeIcon} alt={"badge-icon"}/>
                            <span>{T("Home:Home_Hotel_Incl_Flight")}</span>
                        </div>
                    </div>
                    <div className="small-card-content-holder">
                        <h4 className="heading heading-6 dark-text font-poppings ellipis">
                            {offer?.name || offer?.hotel}
                        </h4>
                        <div className="reviews">
                            <div className="star">
                                {[1, 2, 3, 4, 5].map((item, index) => {
                                    if (item <= Number(offer?.starRating))
                                        return (
                                            <FontAwesomeIcon
                                                key={index}
                                                icon={faStar}
                                                className="filled"
                                            />
                                        );
                                    else
                                        return (
                                            <FontAwesomeIcon
                                                key={index}
                                                icon={faStar}
                                                className="blank"
                                            />
                                        );
                                })}
                                <span>{`${offer?.guestRating || 0} (${
                                    offer?.guestReviewCount || 0
                                })`}</span>
                            </div>
                        </div>
                        <p className="location">
                            <FontAwesomeIcon icon={faMapMarkerAlt}/>
                            {offer?.address || offer?.location}
                        </p>
                        <ul className="info-tags">
                            {filters?.map((filter, index) => {
                                return (
                                    <li
                                        className="veryLight-bg fs-12 medium darkgray-color"
                                        key={index}
                                    >
                                        {filter.title}
                                    </li>
                                );
                            })}
                        </ul>
                        <hr className="seperator"></hr>
                        <div className="logo-holder">
                            {offer?.objHotel?.offerType === "dailytop10" && (
                                <div className="daily-offer-list-small">{T("Home:Home_List_View_Daily_Offer_Text")}</div>
                            )}
                            <img
                                src={AppConfig.getAirlineImageURL(
                                    offer?.objHotel?.newPackagedOffer?.flightOffer?.segments[0]
                                        ?.legs[0]?.marketingAirlineCode
                                )}
                                alt={"card-logo"}
                            />
                            <p>
                                <img src={BadgeIconDark} alt={"badge-icon"}/>{" "}
                                {T("Home:Home_Hotel_Incl_Flight").toLowerCase()}
                            </p>
                        </div>
                        <div className="price-holder">
                            <div className="price">
                                {getCurrencyBadge(offer?.perPersonPrice?.currency || currency)}
                                {offer?.price && (
                                    <PriceBoxes
                                        price={Number(
                                            offer?.perPersonPrice?.totalPrice || offer?.price
                                        ).toFixed(0)}
                                    />
                                )}
                            </div>
                        </div>
                        <p className="price-caption">{T("Home:Home_List_View_Total_Text")}</p>
                    </div>
                </div>
                <FavoriteAccordion offer={offer}/>
            </Accordion>
        </>
    );
};

const PriceBoxes = ({price}) => {
    let value = price.toString().split("");
    let valueIndex = 0;
    let _price = [];
    for (let index = 0; index <= value.length; index++) {
        if (value.length && value[value.length - valueIndex]) {
            _price.unshift(value[value.length - valueIndex]);
        }
        valueIndex++;
    }

    return _price.map((digit, index) => <span key={index}>{digit}</span>);
};

PriceBoxes.propTypes = {
    price: PropTypes.string,
};

HotelItemBranchSmall.propTypes = {
    offer: PropTypes.object,
    index: PropTypes.number,
    handler: PropTypes.func,
};

export default HotelItemBranchSmall;
