import React from "react";
import HotelItem from "../../HotelItem/GridViewCArd";
import Slider from "react-slick";
import CatImage from "../../../assets/images/icons/cat.svg";
import "./style.scss";
import { useSelector } from "react-redux";
import { getCheapestOffers } from "../../../redux/slices/appconfigSlice";
import PropTypes from "prop-types";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const CheapestDestinations = () => {
  const { T } = useTranslationCustom();
  const cheapDestinations = useSelector(getCheapestOffers);

  function SampleNextArrow({onClick}) {
    return (
        <span
            className="fas fa-long-arrow-alt-right slick-arrow arrow-right"
            onClick={onClick}
        ></span>
    );
  }

  function SamplePrevArrow({onClick}) {
    return (
        <span
            className="fas fa-long-arrow-alt-left slick-arrow arrow-left"
            onClick={onClick}
        ></span>
    );
  }

  SampleNextArrow.propTypes = {
    onClick: PropTypes.func,
  }

  SamplePrevArrow.propTypes = {
    onClick: PropTypes.func,
  }

  const settings = {
    // className: "center",
    slidesToShow: 4,
    infinite: cheapDestinations?.length > 3,
    speed: 700,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    arrows: true,
    // centerMode: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          // centerPadding: "100px",
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          // centerPadding: "200px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "50px",
        },
      },
    ],
  };
  return (
    <section id="CheapestDestinations" className="CheapestDestinations-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="title text-center">
              <h2 className="heading heading-1 font-poppings medium">
                {T("Home:Home_Heading_Top_Value_Text")}
              </h2>
            </div>
          </div>
          <div className="col-12">
            <div className="destionation-Slider-wrapper">
              <img src={CatImage} className="Cat-icon" alt={"cat-image"} />
              <Slider className={'slider-outside-arrows'} {...settings}>
                {cheapDestinations?.map((offer, index) => {
                  return <HotelItem key={index} offer={offer} index={index} />;
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheapestDestinations;
