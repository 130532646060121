import React, {useEffect, useState, useRef} from "react";
import {ServiceGetTransactionId} from "../services/AppService";
import {useDispatch} from "react-redux";
import {setBranchData, setTransactionId} from "../redux/slices/appconfigSlice";
import Loader from "../components/Loader/Loader";
import PropTypes from "prop-types";
import branch from "branch-sdk";
import AppConfig from "../helpers/config";
import {isMobile, isTablet} from "react-device-detect";

const TransactionWrapper = ({children}) => {
    const dispatch = useDispatch();
    const fullUrl = window.location.href;
    const [loading, setLoading] = useState(true);
    const hasFetchedTransactionId = useRef(false); // To ensure `getTransactionIdService` runs only once.
    const isFetchingPage = fullUrl.includes("/fetching");

    useEffect(() => {
        if (!isFetchingPage) {
            if (!hasFetchedTransactionId.current) {
                hasFetchedTransactionId.current = true; // Prevent subsequent calls.
                setTimeout(() => {
                    getTransactionIdService();
                }, 1000);
            }

            branch.init(AppConfig.branchKey);

            branch.data((err, data) => {
                if (data?.data_parsed?.payload) {
                    const parsed = JSON.parse(data?.data_parsed?.payload);
                    if (parsed) {
                        console.log({parsed});
                        dispatch(setBranchData(parsed));
                    }
                }
            });

            return () => {
                dispatch(setTransactionId(null));
            };
        } else {
            setLoading(false);
        }
    }, [dispatch, isFetchingPage]);

    const getTransactionIdService = async () => {
        try {
            const response = await ServiceGetTransactionId({
                transaction_channel: "site",
                transaction_campaign: isMobile || isTablet ? "GeniusMobile" : "GeniusWebsite",
            });
            dispatch(setTransactionId(response?.data?.transaction_id));
            setLoading(false); // Stop loading once transaction ID is saved successfully.
        } catch (error) {
            console.error({error});
            setLoading(false); // Stop loading on error as well.
        }
    };

    return <div>{loading ? <Loader /> : children}</div>;
};

TransactionWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default React.memo(TransactionWrapper);
