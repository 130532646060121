import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {GetAppTranslations} from '../../services/AppService';
// import {translations} from "../../i18n/locales/en/new";

// Async thunk to fetch translations from the API
export const fetchTranslations = createAsyncThunk(
    'appTranslations/fetchTranslations',
    async ({locale, lastModified, setTranslationFetching}, {rejectWithValue}) => {
        try {
            const response = await GetAppTranslations(locale, lastModified);
            return {response, locale, setTranslationFetching};
        } catch (err) {
            if (err.response) {
                return rejectWithValue({
                    message: err.message,
                    status: err.response.status,
                    setTranslationFetching
                });
            }
            return rejectWithValue({message: err.message, setTranslationFetching});
        }
    },
);

const initialState = {
    locales: [],
    translations: null,
    status: 'idle',
    currentLanguageCode: '',
    error: null,
    lastModified: '',
};

export const appTranslationSlice = createSlice({
    name: 'appTranslations',
    initialState,
    reducers: {
        resetAppTranslations: () => initialState,
        setAppLocales: (state, action) => {
            state.status = 'idle';
            state.locales = action.payload;
        },
        setModifiedDate: (state, action) => {
            state.lastModified = action.payload;
        },
        setTranslationStatus: (state, action) => {
            state.status = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTranslations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTranslations.fulfilled, (state, action) => {
                state.currentLanguageCode = action.payload?.locale; // storing current locale
                state.translations = action.payload?.response?.data?.data;
                state.lastModified = action.payload?.response?.data?.last_modified;
                state.status = 'succeeded';
                action.payload?.setTranslationFetching(true);
            })
            .addCase(fetchTranslations.rejected, (state, action) => {
                const statusCode = action.payload?.status;
                if (statusCode === 304 || statusCode === 404) {
                    state.status = 'succeeded';
                    action.payload?.setTranslationFetching(true);
                } else {
                    state.status = 'failed';
                }
                state.error = action.error.message;
            });
    },
});

export const getAppTranslations = (state) => state.appTranslations;

export const {setAppLocales, resetAppTranslations} = appTranslationSlice.actions;

export default appTranslationSlice.reducer;
