import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import React, {useEffect, useState} from "react";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {useDispatch, useSelector} from "react-redux";
import AppRoutes from "./routes";
import Loader from "./components/Loader/Loader";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    getLoginConfig, resetLoginConfigState,
} from "./redux/slices/loginconfigSlice";
import TransactionWrapper from "./container/TransactionWrapper";
import {isMobile, isTablet} from 'react-device-detect';
import ScrollLock from "./components/ScrollLock";
import DownloadApp from "./components/DownloadApp";
import TranslationProvider from "./i18n/TranslationProvider";
import AppConfig from "./helpers/config";
import {resetAppTranslations} from "./redux/slices/appTranslationSlice";
import {resetAppConfigState} from "./redux/slices/appconfigSlice";
import {resetBookingSliceState} from "./redux/slices/bookingSlice";
import {resetSearchFiltersState} from "./redux/slices/searchFilterSlice";

function App() {
    const dispatch = useDispatch();
    const appconfig = useSelector((state) => state.appconfig);
    const loginConfig = useSelector(getLoginConfig);
    const [showDownloadSection, setShowDownloadSection] = useState(true);
    const savedVersion = localStorage.getItem('gt_app_version');

    useEffect(() => {
        console.log(savedVersion, AppConfig.webVersion);
        if (savedVersion !== AppConfig.webVersion) {
            resetAppData();
        }
    }, [dispatch]);

    useEffect(() => {
        document.body.id = loginConfig.darkMode ? "darkMode" : "";
    }, [loginConfig.darkMode]);

    async function resetAppData() {
        console.log('Resetting app data');

        // Sequentially dispatch state resets
        dispatch(resetLoginConfigState());
        dispatch(resetAppTranslations());
        dispatch(resetAppConfigState());
        dispatch(resetBookingSliceState());
        dispatch(resetSearchFiltersState());

        // Update local storage
        await new Promise((resolve) => {
            localStorage.setItem('gt_app_version', AppConfig.webVersion);
            resolve(); // Ensures the next operation waits for this
        });

        // Reload the application
        window.location.reload();
    }

    return (
        <GoogleOAuthProvider clientId="752153235622-du75254s7dkvo8l472g3ib552umv4pmt.apps.googleusercontent.com">
            <TransactionWrapper>
                <TranslationProvider>
                    <React.Fragment>
                        <AppRoutes/>
                        {showDownloadSection && (isMobile || isTablet) &&
                            <DownloadApp setShowDownloadSection={setShowDownloadSection}/>}
                        {appconfig?.loadingScreen &&
                            <ScrollLock isOpen={appconfig?.loadingScreen}>
                                <Loader/>
                            </ScrollLock>
                        }
                        <ToastContainer
                            position="bottom-right"
                            autoClose={4000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </React.Fragment>
                </TranslationProvider>
            </TransactionWrapper>
        </GoogleOAuthProvider>
    );
}

export default App;
