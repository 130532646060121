import React from "react";
import LoaderImg from "../../assets/images/Genius-sun-loader.gif";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {getRequestAPIState, terminateRequest} from "../../redux/slices/requestSlice";
import {setActivityIndicator} from "../../redux/slices/appconfigSlice";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const Loader = () => {
    const dispatch = useDispatch();
    const {T} = useTranslationCustom();
    const {apiLoading, apiUrl} = useSelector(getRequestAPIState);

    const handleCancelRequest = () => {
        console.log('terminate it');
        dispatch(setActivityIndicator(false));
        dispatch(terminateRequest()); // Dispatch terminateRequest to cancel API call
    };

    return (
        <div className="loader show">
            {apiLoading && (
                <div className='reset-btn' onClick={handleCancelRequest}>{T('Buttons:Reset')}</div>
            )}
            <div className="loader-content">
                <img src={LoaderImg} className={'loader_spinner'} alt={'loading-image'}/>
                <p>{T("Common:Loading")} ...</p>

                {apiUrl?.includes('/SearchOnlyListing') && (
                    <p className={'mt-3'}>{T('Common:Loading_Screen_Banner')}</p>
                )}
            </div>
        </div>
    );
};

export default Loader;
