import React, { useEffect, useState } from "react";
import AppConfig from "../../helpers/config";
import "rc-slider/assets/index.css";
import "./styles.scss";
import { useSelector } from "react-redux";
import { getLoginConfig } from "../../redux/slices/loginconfigSlice";
import TooltipSlider from "../TooltipSlider";
import PropTypes from "prop-types";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

function RangeBar({ lowPrice, highPrice, onChangePriceHandler = null }) {
  const maxAllowedPrice = 1;
  const { T } = useTranslationCustom();
  const loginconfig = useSelector(getLoginConfig);
  const [priceRange, setPriceRange] = useState({
    low: lowPrice,
    high: highPrice,
  });

  useEffect(() => {
    setPriceRange({ low: lowPrice, high: highPrice });
  }, [lowPrice, highPrice]);

  return (
    <div className="range-bar">
      <div className="caption">
        <p>
          {T("Common:Price")}
          <span> p.p</span>
        </p>
      </div>
      <div className="range-container">
        <p className="range-value highest">
          {loginconfig?.locale?.currency?.symbol}
          {priceRange?.low?.toFixed(0)}
        </p>
        <div className="range-slider-holder">
          <div className="tiles">
            {AppConfig.tileValues(AppConfig.minPrice, AppConfig.maxPrice).map(
              (tile, index) => (
                <Tile
                  key={index}
                  low={priceRange.low}
                  high={priceRange.high}
                  tile={tile}
                />
              )
            )}
          </div>
          <div className="outerslider-container">
            <TooltipSlider
              range
              step={1}
              min={AppConfig.minPrice}
              max={AppConfig.maxPrice}
              defaultValue={[AppConfig.minPrice, AppConfig.maxPrice]}
              value={[priceRange.low, priceRange.high]}
              onChange={(value) => {
                if (value[1] < maxAllowedPrice) {
                  setPriceRange({ low: value[0], high: maxAllowedPrice });
                  return;
                }
                setPriceRange({ low: value[0], high: value[1] });
              }}
              onAfterChange={(value) => {
                if (value[1] < maxAllowedPrice) {
                  onChangePriceHandler({
                    low: value[0],
                    high: maxAllowedPrice,
                  });
                  return;
                }
                onChangePriceHandler({ low: value[0], high: value[1] });
              }}
              style={{ padding: 0 }}
              handleStyle={{
                backgroundColor: "#fff",
                borderColor: "white",
                width: "18px",
                height: "18px",
                marginTop: "-6px",
                cursor: "pointer",
                zIndex: 1,
                opacity: "100%",
              }}
              trackStyle={{
                backgroundColor: "rgb(5, 206, 120)",
              }}
              activeDotStyle={{
                borderWidth: "0px",
                borderColor: "white",
              }}
              dotStyle={{ height: "20px" }}
            />
          </div>
        </div>
        <p className="range-value highest">
          {loginconfig?.locale?.currency?.symbol}
          {priceRange?.high?.toFixed(0)}
        </p>
      </div>
    </div>
  );
}

const Tile = ({ low, high, tile }) => {
  return (
    <div
      className={
        tile.value >= low && tile.value <= high
          ? "default-bg-color"
          : "filled-bg-color"
      }
      style={{
        height: tile.height,
        width: 4,
        borderRadius: 2,
        backgroundColor:
          tile.value >= low && tile.value <= high
            ? '#05CE78'
            : "#00000066",
      }}
    />
  );
};

Tile.propTypes = {
  low: PropTypes.number,
  high: PropTypes.number,
  tile: PropTypes.object,
}

RangeBar.propTypes = {
  lowPrice: PropTypes.number,
  highPrice: PropTypes.number,
  onChangePriceHandler: PropTypes.func,
}

export default RangeBar;
