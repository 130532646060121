import React, {useEffect, useState} from "react";
import FaqAccordion from "../../FaqAccordion/FaqAccordion";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getLoginConfig} from "../../../redux/slices/loginconfigSlice";
import {setActivityIndicator} from "../../../redux/slices/appconfigSlice";
import {FaqService} from "../../../services/FaqService";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const Faq = () => {
    const {T} = useTranslationCustom();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const loginConfig = useSelector(getLoginConfig);
    const [FAQs, setFAQs] = useState([]);

    useEffect(() => {
        getFaq();
    }, []);

    function getFaq() {
        dispatch(setActivityIndicator(true));
        FaqService(loginConfig.locale.code)
            .then(_response => {
                console.log({_response})
                setFAQs(_response?.faqLists?.map((f, index) => ({...f, index})));
                dispatch(setActivityIndicator(false));
            })
            .catch(() => {
                dispatch(setActivityIndicator(false));
            });
    }


    return (
        <section id="faq">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <div className="left-action-back-btn mb-3">
                                <a style={{color: "#fff"}} onClick={() => navigate(-1)}>{T("Buttons:Back")}</a>
                            </div>
                            <h2 className="heading heading-1 font-poppings medium">
                                FAQ: {T("Profile:Find_Answers_Here")}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {FAQs.map((value, index) => (
                            <FaqAccordion
                                key={index}
                                question={value.faqtitle}
                                answer={value.faqtext}
                            ></FaqAccordion>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
