import "./style.scss";
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {SearchCountries} from "../../services/AppService";
import {useDispatch, useSelector} from "react-redux";
import {setDesireCountry} from "../../redux/slices/searchFilterSlice";
import {getLoginConfig} from "../../redux/slices/loginconfigSlice";
import PropTypes from "prop-types";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const DesireCountryModal = (props) => {
    const dispatch = useDispatch();
    const {T} = useTranslationCustom();
    const loginConfig = useSelector(getLoginConfig);
    const [country, setCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [debouncedCountry, setDebouncedCountry] = useState("");

    // Debounce logic: Update debouncedCountry after a delay
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedCountry(country);
        }, 500); // 500ms delay

        return () => {
            clearTimeout(handler); // Clear timeout if country changes before delay ends
        };
    }, [country]);

    // Call API when debouncedCountry changes
    useEffect(() => {
        if (debouncedCountry) {
            const params = {searchTerm: debouncedCountry, Locale: loginConfig?.locale?.code};
            SearchCountries(params).then((response) => {
                setCountries(response?.locations);
            });
        } else {
            setCountries([]); // Clear results when input is empty
        }
    }, [debouncedCountry]);

    const handleInputChange = (e) => {
        setCountry(e.target.value); // Update state immediately on input change
    };

    const selectionHandler = (selectedCountry) => {
        dispatch(setDesireCountry(selectedCountry));
        setCountry("");
        setCountries([]);
        props.onHide();
    };

    return (
        <Modal {...props} size="sm" centered>
            <Modal.Header closeButton onHide={props.onHide}></Modal.Header>
            <Modal.Body>
                <h4 className="fs-22 medium">{T("Filters:Destination_Modal_Heading")}</h4>
                <div className="search-form">
                    <div className="form-group">
                        <input
                            id="destination"
                            name="destination"
                            placeholder={T("Filters:Destination_Modal_Placeholder")}
                            value={country}
                            onChange={handleInputChange}
                        />
                    </div>
                    <button className="btn-surprise" onClick={() => {
                        selectionHandler({
                            airportCode: "001",
                            displayName: loginConfig?.locale?.code === 'en_US' ?
                                "Surprise me - let Genius choose for you" :
                                "Verras me - laat Genius jouw bestemming kiezen"
                            ,
                            geoLocation: null,
                            id: "00001",
                            keyword: "Anywhere",
                            type: "MULTICITY"
                        })
                    }}>{T("Buttons:Surprise_Me")}</button>
                </div>
                <ul className="suggestion-list">
                    {countries &&
                        countries?.map((country, index) => {
                            return (
                                <li
                                    key={index}
                                    dangerouslySetInnerHTML={{__html: country.displayName}}
                                    onClick={() => selectionHandler(country)}
                                />
                            );
                        })}
                </ul>
            </Modal.Body>
        </Modal>
    );
};

DesireCountryModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
};

export default DesireCountryModal;
