import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setActivityIndicator} from "../redux/slices/appconfigSlice";
import {fetchTranslations, setAppLocales} from "../redux/slices/appTranslationSlice";
import {changeAppCurrency, changeAppLocale} from "../redux/slices/loginconfigSlice";
import Loader from '../components/Loader/Loader';
import AppConfig from '../helpers/config';
import PropTypes from 'prop-types';
import {GetAppLocales} from "../services/AppService";
import {setTravelDate} from "../redux/slices/searchFilterSlice";

const TranslationProvider = ({children}) => {
    const dispatch = useDispatch();
    const [tranlsationFetching, setTranslationFetching] = useState(false);
    const {lastModified, currentLanguageCode} = useSelector(state => state.appTranslations);
    const loginConfig = useSelector(state => state.loginconfig);
    const searchfilters = useSelector(state => state.searchFilter);

    const fetchLocalesTranslations = useCallback(
        (languageCode) => {
            dispatch(setActivityIndicator(true));
            setTranslationFetching(false);
            GetAppLocales()
                .then((response) => {
                    const locales = response?.data;
                    dispatch(setAppLocales(locales));
                    dispatch(setActivityIndicator(false));

                    const selectedLocale = languageCode || locales?.find(f => f.short === 'en')?.code;
                    dispatch(fetchTranslations({
                        locale: selectedLocale,
                        lastModified:
                            !currentLanguageCode ||
                            currentLanguageCode !== languageCode ||
                            loginConfig?.locale?.code !== currentLanguageCode ?
                                '' :
                                lastModified,
                        setTranslationFetching
                    }));
                    if (!languageCode) {
                        const defaultLocale = locales?.find(f => f.short === 'en');
                        dispatch(changeAppLocale(defaultLocale));
                    }
                })
                .catch((error) => {
                    console.error('Error fetching locales:', error);
                    dispatch(setActivityIndicator(false));
                });
        },
        [dispatch],
    );

    useEffect(() => {
        const browserLang = navigator.language.split('-')[0];
        const browserCountry = navigator.language.split('-')[1] || '';

        if (!loginConfig?.locale?.code) {
            console.log('choosing locale')
            if (browserLang === 'nl') {
                dispatch(changeAppLocale(AppConfig.appLanguages[0]));
                dispatch(changeAppCurrency(AppConfig.currencies[0]));
            } else if (browserLang === 'en' && browserCountry === 'US') {
                dispatch(changeAppLocale(AppConfig.appLanguages[1]));
                dispatch(changeAppCurrency(AppConfig.currencies[1]));
            } else {
                dispatch(changeAppLocale(AppConfig.appLanguages[1]));
                dispatch(changeAppCurrency(AppConfig.currencies[0]));
            }
        } else {
            fetchLocalesTranslations(loginConfig?.locale?.code);
        }

        return () => {
            const {startDate} = searchfilters?.travelDate || {};
            if (startDate && new Date(startDate) <= new Date()) {
                dispatch(setTravelDate({startDate: '', endDate: ''}));
            }
        };
    }, [dispatch, loginConfig?.locale?.code]);

    return <div>{tranlsationFetching ? children : <Loader/>}</div>;
};

TranslationProvider.propTypes = {
    children: PropTypes.node,
};

export default React.memo(TranslationProvider);
