import "./style.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Image1 from "../../assets/images/hotal-1.png";
import BadgeIcon from "../../assets/images/icons/aroplane-bed.svg";
import { getAppConfig } from "../../redux/slices/appconfigSlice";
import { getSearchFilters } from "../../redux/slices/searchFilterSlice";
import { getLoginConfig } from "../../redux/slices/loginconfigSlice";
import BadgeIconDark from "../../assets/images/icons/aroplane-bed-dark.svg";
import {
  getCurrencyBadge,
  getListingItemFilters,
  handleFavoriteHotelItem,
  navigateToDetailPage,
} from "../../helpers/common";
import AppConfig from "../../helpers/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faMapMarkerAlt,
  faStar,
} from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const HotelItem = ({ offer, index }) => {
  let currency = "$";
  const { T } = useTranslationCustom();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appconfig = useSelector(getAppConfig);
  const loginconfig = useSelector(getLoginConfig);
  const searchfilters = useSelector(getSearchFilters);

  const filters = getListingItemFilters(offer, T, offer?.objHotel?.newPackagedOffer?.flightOffer);
  let up = false;
  let down = false;
  let move = false;

  const navigateToSearchPage = () => {
    if (down && !move && up) {
      navigateToDetailPage(
        offer,
        dispatch,
        loginconfig,
        searchfilters,
        navigate,
        "hotel"
      );
    }
  };

  return (
    <div className="packages-card-grid" onClick={() => navigateToDetailPage(
      offer,
      dispatch,
      loginconfig,
      searchfilters,
      navigate,
      "hotel"
    )}>
      <div className="packages-card-header">
        <img
          src={offer?.thumbnailUrl || Image1}
          className="covered-image"
          alt={"covered-icon"}
          onMouseDown={() => {
            up = false;
            down = true;
            move = false;
          }}
          onMouseUp={() => {
            up = true;
            navigateToSearchPage();
            move = false;
            down = false;
          }}
          onMouseMove={() => {
            move = true;
          }}
        />
        <a
          className="fav-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleFavoriteHotelItem(
              loginconfig,
              dispatch,
              searchfilters,
              offer,
              appconfig,
              index,
              null,
              offer?.objHotel?.newPackagedOffer?.flightOffer
            )
          }
          }
        >
          <FontAwesomeIcon
            icon={faHeart}
            className={offer?.favoriteGuid ? "favorite" : ""}
          />
        </a>
        <div className="card-badge">
          <img src={BadgeIcon} alt={"badge-icon"} />
          <span>{T("Home:Home_Hotel_Incl_Flight")}</span>
        </div>
      </div>
      <div className="card-middle-area">
        <div className="packages-card-Body">
          <div className="reviews">
            <div className="star">
              {[1, 2, 3, 4, 5].map((item, index) => {
                if (item <= Number(offer?.starRating))
                  return (
                    <FontAwesomeIcon
                      key={index}
                      icon={faStar}
                      className="filled"
                    />
                  );
                else
                  return (
                    <FontAwesomeIcon
                      key={index}
                      icon={faStar}
                      className="blank"
                    />
                  );
              })}
            </div>
          </div>
          <h4 className="heading heading-6 dark-text font-poppings ellipis">
            {offer?.name}
          </h4>
          <div className="reviews">
            <div className="star">
              <span>{`${T("Home:Home_List_View_Rating")}: ${offer?.guestRating || 0} (${T("Home:Home_List_View_Reviews")} ${offer?.guestReviewCount || 0})`}</span>
            </div>
          </div>
          <p className="location">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            {offer?.address}
          </p>
          <ul className="info-tags">
            {filters?.map((filter, index) => {
              return (
                <li
                  className="veryLight-bg fs-12 medium darkgray-color"
                  key={index}
                >
                  {filter.title}
                </li>
              );
            })}
          </ul>
          {offer?.objHotel?.offerType === "dailytop10" && (
            <div className="daily-offer">{T("Home:Home_List_View_Daily_Offer_Text")}</div>
          )}
        </div>
        <div className="seprator-mask medium"></div>
        <div className="packages-card-Footer">
          <div className="logo-holder">
            <img
              src={AppConfig.getAirlineImageURL(
                offer?.objHotel?.newPackagedOffer?.flightOffer?.segments[0]
                  ?.legs[0]?.marketingAirlineCode
              )}
              alt={"card-logo"}
            />
            <p>
              <img src={BadgeIconDark} alt={"badge-icon"} />{" "}
            </p>
          </div>
          <div className="price-holder">
            <div className="price">
              {getCurrencyBadge(offer?.perPersonPrice?.currency || currency)}
              {offer?.price && (
                <PriceBoxes
                  price={Number(offer?.perPersonPrice?.totalPrice).toFixed(0)}
                />
              )}
            </div>
            <div className="action">
              <a
                className="action-btn"
                onClick={() =>
                  navigateToDetailPage(
                    offer,
                    dispatch,
                    loginconfig,
                    searchfilters,
                    navigate,
                    "hotel"
                  )
                }
              >
                {T("Buttons:View")}

              </a>
            </div>
          </div>
          <p className="price-caption">{T("Home:Home_List_View_Total_Text")}</p>
        </div>
      </div>
    </div>
  );
};

const PriceBoxes = ({ price }) => {
  let value = price.toString().split("");
  let valueIndex = 0;
  let _price = [];
  for (let index = 0; index <= value.length; index++) {
    if (value.length && value[value.length - valueIndex]) {
      _price.unshift(value[value.length - valueIndex]);
    }
    valueIndex++;
  }

  return _price.map((digit, index) => <span key={index}>{digit}</span>);
};

PriceBoxes.propTypes = {
  price: PropTypes.string,
};

HotelItem.propTypes = {
  offer: PropTypes.object,
  index: PropTypes.number,
};

export default HotelItem;
